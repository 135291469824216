import { Routes, Route } from 'react-router-dom'
import './styles/pagination.css';

// Common Layout
import LoginLayout from '@/Layouts/Login';
import Layout from '@/Layouts/Basic';
import Main from '@/Main'

// Admins Layout
import AdminsUsersCustomersList from '@/pages/admins/users/customers/list';
import AdminsUsersCustomersGet from '@/pages/admins/users/customers/get';

import AdminsUsersAgenciesCreate from '@/pages/admins/users/agencies/create';
import AdminsUsersAgenciesList from '@/pages/admins/users/agencies/list';
import AdminsUsersAgenciesGet from '@/pages/admins/users/agencies/get';


import AdminsBranchesCodesList from '@/pages/admins/branches/codes/list'
import AdminsBranchesCodesCreate from '@/pages/admins/branches/codes/create'
import AdminsBranchesCodesGet from '@/pages/admins/branches/codes/get'

import AdminsHostsPrintersList from '@/pages/admins/hosts/printers/list';
import AdminsHostsPrintersGet from '@/pages/admins/hosts/printers/get';
import AdminsHostsPrintersCreate from '@/pages/admins/hosts/printers/create';

import AdminsPaymentsLogsList from '@/pages/admins/payments/logs/list';
import AdminsPaymentsLogsByAgency from '@/pages/admins/payments/logs/by-agency';

import AdminsCouponsCouponsList from '@/pages/admins/coupons/coupons/list';
import AdminsCouponsCouponsCreate from '@/pages/admins/coupons/coupons/create';

import AdminsImagesList from '@/pages/admins/images/list';
import AdminsJobsList from '@/pages/admins/jobs/list';


// Agencies Layout
import AgenciesBranchesCodesList from '@/pages/agencies/branches/codes/list';
import AgenciesBranchesCodesCreate from '@/pages/agencies/branches/codes/create';
import AgenciesBranchesCodesGet from '@/pages/agencies/branches/codes/get';

import AgenciesHostsPrintersList from '@/pages/agencies/hosts/printers/list';
import AgenciesHostsPrintersGet from '@/pages/agencies/hosts/printers/get';
import AgenciesHostsPrintersCreate from '@/pages/agencies/hosts/printers/create';

import AgenciesPaymentsLogsList from '@/pages/agencies/payments/list';
import AgenciesPaymentsLogsByAgency from '@/pages/agencies/payments/by-agency';

import * as dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
// import { useDispatch, useSelector } from 'react-redux';
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Seoul')

function App() {
  // const c = useSelector((state: any) => {
  //   console.info(state.value)
  //   return state.value
  // })
  // const dispatch = useDispatch()

  // useEffect(() => {
  //   console.log(`reduxCount: ${c}`)
  //   setInterval(() => {
  //     dispatch(increment())
  //     console.log(`reduxCount: ${c}`)
  //   }, 1000)
  // }, [])

  return (
    <>
      {/* <span>C: {c}</span> */}
      <Routes>
        <Route path="/" element={<Main />}></Route>
        <Route path="/login" element={<LoginLayout />}></Route>

        {/* Routes for admins  */}
        <Route path="/admins" element={<Layout />}>
          <Route path="users">
            <Route path="agencies">
              <Route path="" element={<AdminsUsersAgenciesList />}></Route>
              <Route path="list" element={<AdminsUsersAgenciesList />}></Route>
              <Route path="create" element={<AdminsUsersAgenciesCreate />}></Route>
              <Route path=":_id" element={<AdminsUsersAgenciesGet />}></Route>
            </Route>
            <Route path="customers">
              <Route path="" element={<AdminsUsersCustomersList />}></Route>
              <Route path="list" element={<AdminsUsersCustomersList />}></Route>
              <Route path=":_id" element={<AdminsUsersCustomersGet />}></Route>
            </Route>
          </Route>
          <Route path="branches">
            <Route path="codes">
              <Route path="" element={<AdminsBranchesCodesList />}></Route>
              <Route path="list" element={<AdminsBranchesCodesList />}></Route>
              <Route path="create" element={<AdminsBranchesCodesCreate />}></Route>
              <Route path=":_id" element={<AdminsBranchesCodesGet />}></Route>
            </Route>
          </Route>
          <Route path="hosts">
            <Route path="printers">
              <Route path="" element={<AdminsHostsPrintersList />}></Route>
              <Route path="list" element={<AdminsHostsPrintersList />}></Route>
              <Route path=":_id" element={<AdminsHostsPrintersGet />}></Route>
              <Route path="create" element={<AdminsHostsPrintersCreate />}></Route>
            </Route>
          </Route>
          <Route path="payments">
            <Route path="logs">
              <Route path="" element={<AdminsPaymentsLogsList />}></Route>
              <Route path="list" element={<AdminsPaymentsLogsList />}></Route>
              <Route path="by-agency" element={<AdminsPaymentsLogsByAgency />}></Route>
            </Route>
          </Route>
          <Route path="coupons">
            <Route path="coupons">
              <Route path="" element={<AdminsCouponsCouponsList />}></Route>
              <Route path="list" element={<AdminsCouponsCouponsList />}></Route>
              <Route path="create" element={<AdminsCouponsCouponsCreate />}></Route>
            </Route>
          </Route>
          <Route path="images">
            <Route path='' element={<><div><span>images</span></div></>}></Route>
            <Route path="list" element={<AdminsImagesList/>}></Route>
            <Route path="upload" element={<><div><span>images list</span></div></>}></Route>
          </Route>
          <Route path="jobs">
            <Route path="" element={<AdminsJobsList />}></Route>
            <Route path="list" element={<AdminsJobsList />}></Route>
          </Route>
        </Route>

        {/* Routes for agency */}
        <Route path="/agencies" element={<Layout />}>
          <Route path="branches">
            <Route path="codes">
              <Route path="" element={<AgenciesBranchesCodesList />}></Route>
              <Route path="list" element={<AgenciesBranchesCodesList />}></Route>
              <Route path=":_id" element={<AgenciesBranchesCodesGet />}></Route>
              <Route path="create" element={<AgenciesBranchesCodesCreate />}></Route>
            </Route>
          </Route>
          <Route path="hosts">
            <Route path="printers">
              <Route path="" element={<AgenciesHostsPrintersList />}></Route>
              <Route path="list" element={<AgenciesHostsPrintersList />}></Route>
              <Route path=":_id" element={<AgenciesHostsPrintersGet />}></Route>
              <Route path="create" element={<AgenciesHostsPrintersCreate />}></Route>
            </Route>
          </Route>
          <Route path="payments">
            <Route path="logs">
              <Route path="" element={<AgenciesPaymentsLogsList />}></Route>
              <Route path="list" element={<AgenciesPaymentsLogsList />}></Route>
              <Route path="by-agency" element={<AgenciesPaymentsLogsByAgency />}></Route>
            </Route>
          </Route>
        </Route>
      </Routes>

    </>
  );
}

export default App;
